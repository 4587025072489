<template>
  <div>
    <PageHeader
      :title="`FAQ > ${domain.label}`"
      :description="`Gestion des questions`"
      :button="{
        icon: 'fas fa-plus-circle',
        text: 'Créer une question',
        action: openModal
      }"
    />
    <QuestionsSubmenu />

    <PageContent>
      <MVPTable
        :gridData="gridData"
        :options="gridOptions"
        @rowDoubleClicked="openModal"
      />
    </PageContent>

    <t-modal
      name="question-form"
      variant="contentForm"
    >
      <MVPQuestionsForm
        v-if="currentQuestion"
        :question="currentQuestion"
        :questionKey="currentQuestion.uuid"
        @refresh="loadQuestions"
      />
    </t-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { http } from '@/services/api.service'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import MVPTable from '@/components/agGrid/MVPTable.vue'
import QuestionsSubmenu from './QuestionsSubmenu.vue'
import ListRowDetail from './ListRowDetail.vue'
import MVPQuestionsForm from './Form.vue'

const getDocumentsCount = (params) => {
  return params.data.documents.length
}

export default {
  name: 'app-questions-list',
  components: {
    PageHeader,
    PageContent,
    QuestionsSubmenu,
    MVPTable,
    ListRowDetail, //eslint-disable-line
    MVPQuestionsForm
  },
  computed: {
    domain () {
      this.loadQuestions()
      return this.$store.getters.domain
    },
    ...mapGetters([
      'roles',
      'user'
    ]),
    hasEditionRights () {
      return this.roles.some(role => ['admin', 'director', 'community-manager'].includes(role))
    }
  },
  data () {
    return {
      loading: false,
      questions: [],
      all: [],
      searchInput: '',
      currentQuestion: null,
      gridData: null,
      gridOptions: {
        masterDetail: true,
        detailCellRenderer: 'ListRowDetail',
        detailRowAutoHeight: true,
        columnDefs: [
          {
            headerName: 'Type',
            cellRenderer: 'agGroupCellRenderer',
            field: 'type.name',
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
              applyMiniFilterWhileTyping: true
            }
          },
          {
            headerName: 'Catégorie',
            field: 'category.label',
            filter: 'agSetColumnFilter',
            sortable: true
          },
          {
            headerName: 'Question',
            field: 'title',
            filter: 'agTextColumnFilter',
            width: 300,
            sortable: true
          },
          {
            headerName: 'Lectures',
            type: 'numericColumn',
            field: 'consultations_count',
            filter: 'agNumberColumnFilter',
            width: 120,
            sortable: true
          },
          {
            headerName: 'PJ',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            valueGetter: getDocumentsCount,
            width: 80,
            sortable: true
          },
          {
            headerName: 'Avis',
            headerClass: 'ag-center-aligned-header',
            field: 'rating.percent',
            filter: 'agNumberColumnFilter',
            cellRenderer: 'RatingCellRenderer',
            minWidth: 120,
            sortable: true
          },
          {
            headerName: 'Activée',
            headerClass: 'ag-center-aligned-header',
            field: 'is_enabled',
            sortable: true,
            cellRenderer: 'BooleanCellRenderer',
            width: 100,
            cellStyle: {
              'textAlign': 'center'
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              cellRenderer: 'BooleanFilterCellRenderer',
              buttons: ['reset']
            }
          },
          {
            headerName: 'Identifiant unique',
            field: 'uuid',
            width: 100
          }
        ]
      }
    }
  },
  created () {
    this.loadQuestions()
  },
  methods: {
    async search () {
      if (this.searchInput.length > 3) {
        const questions = await http.post('/elasticsearch/search?filter=faq', { query: this.searchInput })
        this.gridData = questions.data
      } else {
        this.gridData = this.all
      }
    },
    async goToQuestion ({ data }) {
      this.$router.push({ path: `/app/question/${data.uuid}` })
    },
    loadQuestions () {
      this.loading = true
      http.get('/faq').then(res => {
        this.loading = false
        this.gridData = res.data
        this.all = res.data
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des questions',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    openModal ({ data }) {
      if (!this.hasEditionRights) {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Vous n\'avez pas les droits pour modifier une question',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'lock'
        })
        return
      }
      if (data) {
        this.currentQuestion = data
      } else {
        this.currentQuestion = {
          type: {
            uuid: null
          },
          category: {
            uuid: null
          }
        }
      }
      this.$modal.show('question-form', data)
    }
  }
}
</script>
